<template>
  <div>
    <main id="content"
          class="broker-content">
      <HeaderBrokerComponent />
      <section class="dashboard-section">
        <div class="container">
          <h3>Dashboard</h3>
          <div class="main-stats">
            <div class="row m-0"
                 v-if="data">
              <div class="col-lg-8 earnings">
                <div class="earnings-description">
                  <i class="icon-price" />
                  <p>
                    ${{ (data.total_earnings - data.total_charges)| numFormat('0,0.00') }}
                    <small>Earnings</small>
                  </p>
                </div>
                <div class="completed">
                  <p class="text-black">
                    ${{ data.completed_earnings| numFormat('0,0.00') }}
                    <small>Completed</small>
                  </p>
                </div>
                <div class="expected">
                  <p class="expected-color">
                    ${{ data.expected_earnings| numFormat('0,0.00') }}
                    <small>Expected</small>
                  </p>
                </div>
                <div class="completed">
                  <p class="expected-color">
                    ${{ data.total_charges| numFormat('0,0.00') }}
                    <small>Charges</small>
                  </p>
                </div>
              </div>
              <div class="col-lg-4 bookings">
                <div class="booking-date">
                  <i class="icon-calendar" />
                  <p>
                    {{ data.bookings ? data.bookings : '0' }}
                    <small>{{ month }} reservations</small>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row m-0 mt-2 main-stats-disclaimer">
            * Current values are estimated and could change throughout the month.
          </div>
          <h5>Latest reservations</h5>
          <div class="row">
            <div class="col-12">
              <div class="accordion-table">
                <!-- title of accordion -->
                <ul class="head-accordion">
                  <li>PROPERTY</li>
                  <li>DATES</li>
                  <li>NIGHTS</li>
                  <li>$ RENT</li>
                  <li>$ EARNINGS</li>
                  <li>STATUS</li>
                </ul>
                <!-- accordion -->
                <vsa-list>
                  <vsa-item v-for="(reservation, index) in reservations"
                            :key="index">
                    <vsa-heading>
                      <!-- property -->
                      <div class="item-information-accordion-li">
                        <div class="d-flex align-items-center">
                          <div class="thumb">
                            <img :src="reservation.property_image.thumbnail">
                          </div>
                          <h6>{{ reservation.property_name }}<small>{{ reservation.property_location }}</small></h6>
                        </div>
                      </div>
                      <!-- dates -->
                      <div class="item-information-accordion-li">
                        {{ reservation.check_in }} - {{ reservation.check_out }}
                      </div>
                      <!-- nights -->
                      <div class="item-information-accordion-li">
                        {{ reservation.nights }}
                      </div>
                      <!-- total -->
                      <div class="item-information-accordion-li">
                        ${{ reservation.total| numFormat('0,0.00') }}
                      </div>
                      <!-- earnings -->
                      <div class="item-information-accordion-li">
                        ${{ reservation.earnings| numFormat('0,0.00') }}
                      </div>
                      <!-- status -->
                      <div class="item-information-accordion-li status">
                        {{ reservation.status }}
                      </div>
                      <div class="item-information-accordion-li">
                        <i class="icon-cheveron-down" />
                      </div>
                    </vsa-heading>
                    <vsa-content>
                      <div class="cont">
                        <div class="content-description">
                          <h6><small>Channel</small></h6>
                          <p>{{ reservation.channel }}</p>
                        </div>
                        <div class="content-description">
                          <h6><small>Price / Night</small></h6>
                          <p>${{ (reservation.total / reservation.nights)| numFormat('0,0.00') }}</p>
                        </div>
                        <div class="content-description"
                             v-if="reservation.booking_earnings">
                          <h6><small>Booking earnings</small></h6>
                          <p>${{ reservation.booking_earnings| numFormat('0,0.00') }}</p>
                        </div>
                        <div class="content-description"
                             v-if="reservation.plus_booking_earnings">
                          <h6><small>Self Booking earnings</small></h6>
                          <p>${{ reservation.plus_booking_earnings| numFormat('0,0.00') }}</p>
                        </div>
                        <div class="content-description"
                             v-if="reservation.other_earnings > 0">
                          <h6><small>Other earning</small></h6>
                          <p>${{ reservation.other_earnings| numFormat('0,0.00') }}</p>
                        </div>
                        <!-- <div class="content-description">
                          <h6><small>Guest</small></h6>
                          <p>{{ reservation.guest }}</p>
                        </div> -->
                      </div>
                    </vsa-content>
                  </vsa-item>
                </vsa-list>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <myFooter />
  </div>
</template>

<script>
import { VsaList, VsaItem, VsaHeading, VsaContent } from 'vue-simple-accordion';
import 'vue-simple-accordion/dist/vue-simple-accordion.css';
import HeaderBrokerComponent from '../../components/broker/Header.vue';

export default {
  components: {
    VsaList,
    VsaItem,
    VsaHeading,
    VsaContent,
    HeaderBrokerComponent
  },
  data() {
    return {
      data: null,
      reservations: null,
    };
  },
  name: 'Dashboard',
  computed: {
    month() {
      const d = new Date();
      const month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      return month[d.getMonth()];
    }
  },
  created() {
    this.$axios.get('/v1/brokers/bookings').then((reservations) => {
      this.reservations = reservations.data;
    });
    this.$axios.get('/v1/brokers').then((response) => {
      this.data = response.data;
    });
  },
};
</script>

<style>
  .accordion-table h6 {
    max-width: 140px;
  }
</style>
